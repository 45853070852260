<template>
  <div id="PermissionSettings">
    <content-title :nav="nav"></content-title>
    <el-button
      style="color: #ffffff; background-color: #ff7e00; margin-bottom: 20px"
      @click="addRoot"
      >新增根菜单</el-button
    >
    <div class="title">
      海马运动后台菜单权限树
    </div>
    <div>
      <el-tree
        :data="data"
        node-key="id"
        :props="defaultProps"
        :expand-on-click-node="false"
      >
        <span class="custom-tree-node" slot-scope="{ node, data }">
          <span style="font-size: 16px">{{ node.label }}</span>
          <span class="handle">
            <el-button type="text" size="mini" @click="() => append(data)">
              添加
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click="() => edit(data)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click="() => remove(data)"
            >
              删除
            </el-button>
          </span>
        </span>
      </el-tree>
    </div>

    <!--新增  -->
    <el-dialog :visible.sync="dialogVisible" width="40%" title="新增">
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          :close-on-click-modal="false"
        >
          <el-form-item label="菜单名称" prop="name" required>
            <el-input v-model="ruleForm.name" placeholder="请输入菜单名称" ></el-input>
          </el-form-item>
          <el-form-item label="菜单描述" required>
            <el-input v-model="ruleForm.descr" placeholder="请输入菜单描述"></el-input>
          </el-form-item>
          <el-form-item label="排序" required>
            <el-input v-model="ruleForm.seq" placeholder="排序，越小越靠前"></el-input>
          </el-form-item>
          <el-form-item label="路由地址" prop="routeUrl">
            <el-input v-model="ruleForm.routeUrl" placeholder="请输入路由地址"></el-input>
          </el-form-item>
          <el-form-item label="图标" >
            <el-input v-model="ruleForm.image_url" placeholder="请输入element-ui图标名称"></el-input>
          </el-form-item>
          <el-form-item label="是否按钮" >
            <el-switch v-model="is_button"></el-switch>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          @click="onsubmit"
          >{{editWay == 'add' ? '添加' : '修改'}}</el-button
        >
      </span>
    </el-dialog>

    <!--删除  -->
    <el-dialog :visible.sync="deldialogVisible" width="40%" title="删除">
      <div>
        确定删除,删除后不可恢复
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="deldialogVisible = false">取 消</el-button>
        <el-button
          style="background-color: #ff7e00; color: #ffffff"
          @click="onDel"
          >删除</el-button
        >
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      editWay:'add',
      currentPage: 1,
      total: 0,
      dialogVisible: false,
      deldialogVisible: false,
      form: {},
      typeList: [],
      is_button: false,
      nav: { firstNav: "权限管理中心", secondNav: "权限设置" },
      tableData: [],
      ruleForm: {
       
      },
      delData:{},
      parent_id:0,
      data: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
            },
            {
              label: "二级 1-1",
            },
          ],
        },
        {
          label: "一级 2",
          children: [
            {
              label: "二级 2-1",
              children: [
                {
                  label: "三级 2-1-1",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "childrens",
        label: "name",
      },
      rules:{
        name: [
            { required: true, message: '请输入菜单名称', trigger: 'blur' },
          ],
           descr: [
            { required: true, message: '请输入菜单描述', trigger: 'blur' },
          ],
           seq: [
            { required: true, message: '请输入排序', trigger: 'blur' },
          ],
      }
    };
  },
  created() {
    this.getList();
  },

  methods: {
    //菜单列表
    getList() {
      let params = {
        type: "haima_houtai",
      };
      this.$axios({
        url: "authorization/menu/queryMenuList",
        method: "get",
        params,
      }).then((res) => {
        this.data = res.data.data;
      });
    },
    //菜单类型
    getType() {
      this.$axios({
        url: "authorization/menu/queryMenuType",
        method: "get",
      }).then((res) => {
        this.typeList = res.data.data;
      });
    },
    addMenu(){
      let data = {
        type: "haima_houtai",
        name:this.ruleForm.name,
        descr:this.ruleForm.descr,
        seq:this.ruleForm.seq || 0,
        routeUrl:this.ruleForm.routeUrl,
        image_url:this.ruleForm.image_url,
        add_user:'1',
        parent_id: this.parent_id || 0
      };
      this.$axios({
        url: "authorization/menu/insert",
        method: "post",
        data,
      }).then((res) => {
        // this.data = res.data.data;
      this.dialogVisible = false;
        this.$message({message:res.data.message})
        this.getList() 
      });
    },
    updateMenu(){
       let data = {
        id:this.ruleForm.id,
        type: "haima_houtai",
        name:this.ruleForm.name,
        descr:this.ruleForm.descr,
        seq:this.ruleForm.seq || 0,
        routeUrl:this.ruleForm.routeUrl,
        image_url:this.ruleForm.image_url,
        update_user:'1',
        parent_id:this.ruleForm.parent_id
      };
      this.$axios({
        url: "authorization/menu/update",
        method: "post",
        data,
      }).then((res) => {
        // this.data = res.data.data;
      this.dialogVisible = false;
        this.$message({message:res.data.message})
        this.getList() 
      });
    },
    delMenu(){
       let data = {
        id:this.delData.id,
        parent_id:this.delData.id.parent_id,
        status: 1
      };
      this.$axios({
        url: "authorization/menu/update",
        method: "post",
        data,
      }).then((res) => {
        // this.data = res.data.data;
      this.deldialogVisible = false;
        this.$message({message:res.data.message})
        this.getList() 
      });
    },
    onsubmit(){
      this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if(this.editWay == 'add'){
            this.addMenu()

            }else{
            this.updateMenu()

            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    handellable(data) {
      if (data instanceof Array) {
        data.forEach((item) => {
          item.label;
        });
      }
    },
    addRoot(){
       this.editWay='add'
      this.parent_id = 0
      this.dialogVisible = true;
    },
    append(data) {
       this.editWay='add'
      this.parent_id = data.id
      this.dialogVisible = true;
    },
     edit(data) {
      this.editWay='edit'
      this.ruleForm = {...data}
      this.parent_id = 0
      this.dialogVisible = true;
    },
    remove(data){
      this.delData =data
      this.deldialogVisible = true
    },
    onDel(){
      this.delMenu()
    },
    currentPageChange(v) {
      this.currentPage = v;
      this.getList();
    },
    handleChanges(data, checked, indeterminate) {
      if (checked) {
        console.log(data);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-tree .el-tree-node {
  padding: 4px;
}
.el-input{
  width: 80% !important;
}
.title{
  flex-shrink: 20px;
  line-height: 2;
}
#PermissionSettings {
  background-color: #ffffff;
  padding: 0 20px;
}

.cell {
  text-align: center;
}

.handle {
  margin-left: 30px;
}
</style>
